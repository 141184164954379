<template lang="html">
  <div v-if="title !== '' && description !== ''" class="content" @click="$emit('reset-event')">
    <div class="results">
      <div class="group">
        <div class="box">
          <div class="wrap">
            <p class="info">{{ title }}</p>
            <p class="info title phone" v-html="description"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: [
      "reset-event"
  ],
  props: [
      'title',
      'description',
  ],
  name: "EventResult"
}
</script>


<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_results.scss";
</style>
